import React from "react";
import ServicesHead from "../components/ServicesHead";
import LocationsComponent from "../components/LocationsComponent";

const ContactUsPage = () => {
  return (
    <>
      <ServicesHead
        title={"Contact Us"}
        desc={
          "We’d love to hear from you! Whether you have a question, need assistance, or just want to share your feedback, we’re here to help."
        }
      />
      <LocationsComponent />
    </>
  );
};

export default ContactUsPage;
