import React from "react";
import "../styles/locationComponents.css";

const LocationsComponent: React.FC = () => {
  return (
    <div className="container location-wrapper">
      <div className="row">
        {/* Left Section: Location Details */}
        <div className="col-12 col-md-5 location-details p-4 mb-4 mb-md-0">
          <h4 className="mb-4">Let’s Collaborate with Us!</h4>
          <p className="mb-4">
            We are always ready to help you at any time, let’s talk together.
          </p>

          <div className="contact-info">
            <h4 className="" style={{ fontSize: "18px" }}>
              Address
            </h4>
            <p className="mb-3">
              <i className="bi bi-geo-alt-fill me-2"></i>
              Bhatti Arcade, Tulsa Rd, Lalazar, Rawalpindi, Punjab
            </p>
            <h4 className="" style={{ fontSize: "18px" }}>
              Email Address
            </h4>
            <p className="mb-3">
              <i className="bi bi-envelope-fill me-2"></i>
              info@devcosmic.com
            </p>
            <h4 className="" style={{ fontSize: "18px" }}>
              Contact With Us
            </h4>
            <p className="mb-3">
              <i className="bi bi-telephone-fill me-2"></i>+92 331 9295519
            </p>
          </div>
        </div>
        <div className="col-12 col-md-7 form-section p-4">
          <p>
            Fill out the form, and our rep will contact you shortly to schedule
            the first discussion of your project.
          </p>
          <form>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Full Name"
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Company"
              />
            </div>
            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Work Email"
              />
            </div>
            <div className="mb-3">
              <input
                type="tel"
                className="form-control"
                placeholder="Work Phone"
              />
            </div>
            <div className="mb-3">
              <textarea
                className="form-control"
                rows={3}
                placeholder="Message"
              ></textarea>
            </div>
            <button
              type="submit"
              className="btn  w-100"
              style={{ backgroundColor: "#041527", color: "#ffffff" }}
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LocationsComponent;
