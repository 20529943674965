import React from "react";
import { Container } from "react-bootstrap";
import img from "../assets/CleanShot 2023-07-29 at 11.40 1.png";
import img2 from "../assets/Section.png";
import "../styles/industryexperties.css";

const IndustryExperties = () => {
  return (
    <>
      <section className="industry-experties" id="Expertise">
        <Container>
          <div className="text-center">
            <h2 className="mainHeading themeWhite">Industry Expertise</h2>
            <p className="para">
              Benefit from our deep industry knowledge and expertise. We
              understand the intricacies of various sectors, allowing us to
              provide tailored solutions that address the specific challenges
              and opportunities unique to your industry.
            </p>
            <div>
              <img src={img} alt="" />
            </div>
          </div>
        </Container>
        <div className="moon-img">
          <img src={img2} alt="" style={{ width: "100%" }} />
        </div>
      </section>
    </>
  );
};

export default IndustryExperties;
