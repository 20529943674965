import React from "react";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import "../styles/banner.css";

const Banner = () => {
  return (
    <>
      <div className="banner-section" id="banner">
        <Container>
          <Row className="Banner-row">
            <Col md={1}></Col>
            <Col md={11} className="col-detail">
              <h2 className="main-heading">
                Where
                <span style={{ fontWeight: "bold" }} className="gradient">
                  Your
                </span>{" "}
                Idea Takes Center Stage in the Digital World!
              </h2>
              <p className="para">
                Transforming your vision into digital reality for standout
                growth and exposure
              </p>
              <div>
                <button className="learn-more">
                  <span className="circle" aria-hidden="true">
                    <span className="icon arrow"></span>
                  </span>
                  <span className="button-text">Learn More</span>
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Banner;
