import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "../styles/OurTeam.css";
import img1 from "../assets/aliyan.webp";
import img2 from "../assets/Hamza-Arif-hamzaarif-arif-90-•-Instagram-photos-and-videos.webp";
import img3 from "../assets/ahmed.webp";
import img4 from "../assets/Syed-Asadullah-iasadullah-Instagram-profile.webp";
import img5 from "../assets/no-profile-picture-female-15290-656adb0522706.webp";
import img6 from "../assets/zeeshan.png";
import img7 from "../assets/hamza2.jpeg";
import Navbar from "../components/Navbar";

const OurTeamPage = () => {
  return (
    <>
      <Navbar />
      <section className="ourTeam">
        <Container>
          <Row
            style={{
              textAlign: "center",
              marginBottom: "10px",
              justifyContent: "center",
            }}
          >
            <Col
              sm={8}
              md={8}
              lg={4}
              style={{
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <h2 className="mainHeading ourTeamHeading">
                Hello we are DevCosmic!!
              </h2>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              <div className="image-div">
                <Image src={img1} fluid style={{ height: "100%" }} />
                <div className="team-desc">
                  <h4>CEO</h4>
                  <p>Muhammad ALiyan</p>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              <div className="image-div">
                <Image src={img2} fluid style={{ height: "100%" }} />
                <div className="team-desc">
                  <h4>Co-founder</h4>
                  <p>Hamza Arif</p>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              style={{ textAlign: "center", marginBottom: "10px" }}
              sm={12}
              md={6}
              lg={4}
            >
              <div className="image-div">
                <Image src={img3} fluid style={{ height: "100%" }} />
                <div className="team-desc">
                  <h4>CTO</h4>
                  <p>Muhammad Ahmad</p>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              style={{ textAlign: "center", marginBottom: "10px" }}
              sm={12}
              md={6}
              lg={4}
            >
              <div className="image-div">
                <Image src={img4} fluid style={{ height: "100%" }} />
                <div className="team-desc">
                  <h4>Full Stack Developer</h4>
                  <p>Syed Asadullah</p>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              style={{ textAlign: "center", marginBottom: "10px" }}
              sm={12}
              md={6}
              lg={4}
            >
              <div className="image-div">
                <Image src={img5} fluid style={{ height: "100%" }} />
                <div className="team-desc">
                  <h4>Business Development Head</h4>
                  <p>Summaiya </p>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              sm={8}
              md={8}
              lg={4}
              style={{
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              {" "}
              <div className="image-div">
                <Image src={img6} fluid style={{ height: "100%" }} />
                <div className="team-desc">
                  <h4>React Developer</h4>
                  <p>Zeeshan Naseeb</p>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              style={{ textAlign: "center", marginBottom: "10px" }}
              sm={12}
              md={6}
              lg={4}
            >
              <div className="image-div">
                <Image src={img5} fluid style={{ height: "100%" }} />
                <div className="team-desc">
                  <h4>Designer Team Lead</h4>
                  <p>Rida Farooq</p>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              <div className="image-div">
                <Image src={img7} fluid style={{ height: "100%" }} />
                <div className="team-desc">
                  <h4>Backend Developer</h4>
                  <p>Muhammad Hamza</p>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default OurTeamPage;
