import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import Container from "react-bootstrap/Container";
import logo from "../assets/Logo 4.png";
import "../styles/navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <header>
        <Container className="navbar-full">
          <Link to="/" className="navbar-link-li">
            <div className="logo">
              <img src={logo} height={36} width={156} alt="" />
            </div>
          </Link>
          <nav>
            <ul className="navbar-link">
              <Link to="/" className="navbar-link-li">
                Home
              </Link>
              <Link to="/services" className="navbar-link-li">
                Services
              </Link>
              <Link to="/technologies" className="navbar-link-li">
                Technologies
              </Link>
              <Link to="/casestudie" className="navbar-link-li">
                Case Studies
              </Link>
              <Link to="/ourteam" className="navbar-link-li">
                Our Team
              </Link>
            </ul>
          </nav>
          <div>
            <Link to="/contactus" className="constact-us">
              Contact Us
            </Link>
          </div>
          <div className="mobile-menu">
            <button className="navbar-toggle" onClick={toggleNavbar}>
              <div className="ham-icon">
                <FontAwesomeIcon icon={faBars} />
              </div>
            </button>
            <div className={`menu-items ${isOpen ? "open" : ""}`}>
              <ul>
                <li onClick={toggleNavbar}>
                  <Link to="/">Home</Link>
                </li>
                <li onClick={toggleNavbar}>
                  <Link to="/services">Services</Link>
                </li>
                <li onClick={toggleNavbar}>
                  <Link to="/casestudie">Case Studies</Link>
                </li>
                <li onClick={toggleNavbar}>
                  <Link to="/ourteam">Our Team</Link>
                </li>

                <li onClick={toggleNavbar}>
                  <Link to="/contactus" className="constact-us-responsive">
                    Contact Us
                  </Link>
                  {/* <span className="constact-us-responsive">Contact Us</span> */}
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </header>
    </>
  );
};

export default Navbar;
