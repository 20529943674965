import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../styles/contactus.css";

const ContactUs = () => {
  return (
    <>
      <Container className="contactUsContainer" id="contact">
        <Row className="contactUs_Banner">
          <Col className="d-flex align-items-center">
            <h2 className="mainHeading">Have a project in mind?</h2>
          </Col>

          <Col className="d-flex justify-content-center align-items-center">
            <p className="para themeBlack" style={{ marginBottom: "0" }}>
              Let’s bring your vision to life! Drop us a message, and we'll get
              back to you soon.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactUs;
