import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import logo from "../assets/Logo 4.png";
import "../styles/footer.css";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer className="bg-light text-center text-lg-start">
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
            <div>
              <img src={logo} height={36} width={156} alt="" />
            </div>
            <p>
              Transforming your vision into digital reality for standout growth
              and exposure.
            </p>
          </div>

          <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">Company</h5>
            <ul className="list-unstyled mb-0 ">
              <li className="navbar-link-li">
                <Link to="/" className="navbar-link-li">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/services" className="navbar-link-li">
                  Services
                </Link>
              </li>
              <li>
                <Link to="/technologies" className="navbar-link-li">
                  Technologies
                </Link>
              </li>
              <li className="navbar-link-li">
                <Link to="/casestudie" className="navbar-link-li">
                  Case Studies
                </Link>
              </li>
              <li className="navbar-link-li">
                <Link to="/ourteam" className="navbar-link-li">
                  Our Team
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">Connect With Us</h5>
            <p>
              Connect with us to stay updated and witness our exciting journey
              as we innovate, grow, and bring our vision to life!
            </p>
            <div>
              <a href="#!" className="text-dark me-4">
                <FaFacebook />
              </a>
              <a href="#!" className="text-dark me-4">
                <FaTwitter />
              </a>
              <a href="#!" className="text-dark me-4">
                <FaInstagram />
              </a>
              <a href="#!" className="text-dark me-4">
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center p-3 bg-light">
        © 2024 Copyright by Devit. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
