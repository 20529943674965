import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "../styles/OurTeam.css";
import img1 from "../assets/aliyan.webp";
import img2 from "../assets/Hamza-Arif-hamzaarif-arif-90-•-Instagram-photos-and-videos.webp";
import img3 from "../assets/ahmed.webp";
import img4 from "../assets/Syed-Asadullah-iasadullah-Instagram-profile.webp";
import img5 from "../assets/no-profile-picture-female-15290-656adb0522706.webp";
import { useNavigate } from "react-router-dom";

const OurTeam = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="ourTeam" id="team">
        <Container>
          <div className="text-center">
            <h2 className="mainHeading">Our Team</h2>
            <p className="para themeBlack ">
              Meet the talented individuals behind this. Our team is a diverse
              mix of experts, each bringing unique skills and perspectives to
              the table. Together, we collaborate to turn ideas into reality and
              drive success for our clients.
            </p>
          </div>
          <Row
            style={{
              textAlign: "center",
              marginBottom: "10px",
              justifyContent: "center",
            }}
          >
            <Col
              sm={12}
              md={6}
              lg={4}
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              <div className="image-div">
                <Image src={img1} fluid style={{ height: "auto" }} />
                <div className="team-desc">
                  <h4>Founder</h4>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              <div className="image-div">
                <Image src={img2} fluid style={{ height: "auto" }} />
                <div className="team-desc">
                  <h4>Co-Founder</h4>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              <div className="image-div">
                <Image src={img3} fluid style={{ height: "auto" }} />
                <div className="team-desc">
                  <h4>CEO</h4>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              style={{ textAlign: "center", marginBottom: "10px" }}
              sm={12}
              md={6}
              lg={4}
            >
              <div className="image-div">
                <Image src={img4} fluid style={{ height: "auto" }} />
                <div className="team-desc">
                  <h4>CTO</h4>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              style={{ textAlign: "center", marginBottom: "10px" }}
              sm={12}
              md={6}
              lg={4}
            >
              <div className="image-div">
                <Image src={img5} fluid />
                <div className="team-desc">
                  <h4>CMO</h4>
                </div>
                <div className="shade"></div>
              </div>
            </Col>
            <Col
              style={{
                textAlign: "center",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              sm={8}
              md={8}
              lg={4}
              onClick={() => {
                navigate("/ourteam");
              }}
            >
              <h2
                className="mainHeading "
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
                style={{ border: "none" }}
              >
                See more
              </h2>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default OurTeam;
