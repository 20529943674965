import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import img1 from "../assets/Rectangle 7.webp";
import img2 from "../assets/Group 3.webp";
import img3 from "../assets/desktop 1.webp";
import "../styles/LatestWork.css";

type Props = {
  title: string;
  json: {
    title: string;
    desc: string;
  }[];
};

const LatestWork = (props: Props) => {
  return (
    <>
      <section className="Card-grid-section">
        <Container>
          <h2
            className="main-heading"
            style={{ color: "#041527", fontSize: "40px" }}
          >
            {props.title}
          </h2>
          <Row xs={1} md={1} lg={3} className="card-grid">
            {props.json.map((item: any, index: any) => (
              <Col>
                <div
                  className={index % 2 === 0 ? "lastcard" : "lastcard-middle"}
                >
                  <img
                    src={index % 2 === 0 ? img1 : index % 2 !== 0 ? img2 : img3}
                    alt=""
                  />
                  <h2>{item.title}</h2>
                  <p>{item.desc}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default LatestWork;
