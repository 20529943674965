import React from "react";
import { Container } from "react-bootstrap";
import "../styles/Profile.css";
// import Img from "../assets/image.png";
import Slider from "react-slick";

const Profile = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const testimonials = [
    {
      name: "Emily Johnson",
      testimonial:
        "Using the **Lookup app** has changed how I shop online. It finds the best deals in seconds, and I save so much time with its personalized recommendations. Highly recommend for busy shoppers!",
    },
    {
      name: "Mateo Garcia",
      testimonial:
        "With **PDTMA**, navigating complex systems is so easy. The design is intuitive, and it really feels like everything is right where I need it. Our workflow has never been smoother!",
    },
    {
      name: "Sofia Martinez",
      testimonial:
        "Managing reservations for **Butt Karahi** has been so efficient with the app. Online bookings have boosted our customer satisfaction and helped us keep track of reservations seamlessly!",
    },
    {
      name: "Liam Brown",
      testimonial:
        "As a part of an NGO, **Vision** has made managing applications so simple. Now we can easily connect with volunteers and beneficiaries without the usual hassle.",
    },
    {
      name: "Lucía Pérez",
      testimonial:
        "Partnering with **Devcosmic** helped us scale quickly. They provided great marketing strategies and a skilled developer who delivered exactly what we needed.",
    },
  ];

  return (
    <>
      <Container id="testimonial">
        <div className="profile-section">
          <Slider {...settings}>
            {testimonials.map((data, index) => (
              <div
                className="sliderDIv "
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className="col-md-6 ">
                  <div className="profile-left-img">
                    <div className="profile-staric">*</div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="profile-right-content">
                    <h1
                      className="themeBlack"
                      style={{ fontSize: "18px", marginBottom: "20px" }}
                    >
                      {data.testimonial}
                    </h1>
                    <p
                      className="para themeBlack"
                      style={{ fontSize: "18px", marginBottom: "20px" }}
                    >
                      {data.name}_______________
                    </p>
                  </div>
                  {/* <span className="caseBtn">View More</span> */}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </>
  );
};

export default Profile;
