import React from "react";
import "../styles/LatestProject.css";
import { Container } from "react-bootstrap";
import sidImg from "../assets/vectorRight.png";
import img1 from "../assets/designer-working.png";
import img2 from "../assets/Logo_Notion_Padding-white.svg.png";
import img3 from "../assets/Logo_Inter_Padding-white.svg.png";
import img4 from "../assets/financial-literacy.png";

const LatestProject = () => {
  const services = [
    {
      title: "MVP DEVELOPMENT",
      description:
        "We specialize in rapid MVP development, helping businesses launch functional, scalable products quickly to validate ideas, gather feedback, and iterate efficiently.",
      imageAlt: "MVP Development Illustration",
    },
    {
      title: "Digital Marketing",
      description:
        "We offer tailored digital marketing services, helping businesses grow online through targeted strategies, driving engagement, traffic, and conversions effectively.",
      imageAlt: "Digital Marketing Illustration",
    },
    {
      title: "Remote Development",
      description:
        "We provide expert remote development services, delivering high-quality software solutions seamlessly, regardless of location, with a focus on efficiency and collaboration.",
      imageAlt: "Remote Development Illustration",
    },
    {
      title: "Business Management",
      description:
        "We offer comprehensive business management services, optimizing operations, improving efficiency, and driving growth through strategic planning and expert execution.",
      imageAlt: "Business Management Illustration",
    },
  ];

  const images = [img1, img2, img3, img4];

  return (
    <>
      <Container id="latestProject">
        <div className="text-center">
          <h2 className="mainHeading">LATEST PROJECTS</h2>
          <p className="para themeBlack ">
            Here are some of our latest developments!
          </p>
        </div>
        {services.map((service, index) => (
          <div className="row" style={{ marginBottom: "20px" }} key={index}>
            <div
              className="col-md-6 banner-latestProject text-white p-5 right-side"
              style={{ minHeight: "0" }}
            >
              <h1 className="display-4">{service.title}</h1>
              <p className="para lead my-4">{service.description}</p>
              <span className="btn btn-outline-light para lead">View More</span>
              <div className="side-Img">
                <img src={sidImg} alt="" />
              </div>
            </div>

            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <img
                src={images[index]} // Dynamically select image based on index
                alt={service.imageAlt}
                className="img-fluid"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        ))}
      </Container>
    </>
  );
};

export default LatestProject;
