import React from "react";
import ServicesHead from "../components/ServicesHead";
import LatestProject from "../components/LatestProject";
import OurProduct from "../components/OurProduct";
import ourservices from "../utils/Ourservices.json";

const ProjectPage = () => {
  return (
    <>
      <ServicesHead
        title={"Case studies"}
        desc={
          "We play to our strengths: Ideating, creating and shipping wonderful things on the internet."
        }
      />
      <OurProduct title={"Trusted by"} OurServices={ourservices} />
      <LatestProject />
    </>
  );
};

export default ProjectPage;
