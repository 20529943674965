import React from "react";
import ServicesHead from "../components/ServicesHead";
import AboutUs from "../components/AboutUs";
import ContactUs from "../components/ContactUs";
import LocationsComponent from "../components/LocationsComponent";
import LatestWork from "../components/LatestWork";
import OurWork from "../utils/OurWork.json";

const ServicesPage = () => {
  return (
    <>
      <ServicesHead
        title={"Services"}
        desc={"If it’s digital, you name it and we do it!"}
      />
      <AboutUs
        title={"DIGITAL MARKETING"}
        secondHeading={
          "The power of digital is now unbeknownst to none! Our primary aim is to help brands find the perfect digital fit for them and help them build a strategy that will help them truly leverage the respective digital channel helping them maximize their ROI. These include but are not limited to:"
        }
        desc={""}
        display={"none"}
        img={"1"}
        list={[
          "Social Media Marketing",
          "Search Engine Marketing",
          "Google Ads",
          "Facebook Ads",
        ]}
      />
      <AboutUs
        title={"Web Applications"}
        secondHeading={
          "Build dynamic, responsive, and scalable web apps tailored to your business goals, ensuring exceptional performance across devices."
        }
        desc={""}
        display={"none"}
        img={"2"}
        list={[
          "Protfolio websites",
          "Content Management System (CMS)",
          "Landing Pages",
          "Custom Web Applications",
          "Saas Applications",
        ]}
      />
      <AboutUs
        title={"Applications Development"}
        secondHeading={
          "Develop apps compatible with multiple platforms to maximize reach and usability without compromising quality."
        }
        desc={""}
        display={"none"}
        img={"3"}
        list={[
          "IOS App Developement",
          "Android App Developement",
          "Desktop App Developement",
          "Cross Platform App Developement",
        ]}
      />
      <AboutUs
        title={"AI Models & Applications"}
        secondHeading={
          "Implement intelligent, data-driven solutions to automate processes, enhance decision-making, and deliver personalized user experiences."
        }
        desc={""}
        display={"none"}
        img={"4"}
        list={["Chat Boots", "Machine Learning", "Deep Learning", "NLP"]}
      />{" "}
      <LatestWork
        title={"Solutions for every software project"}
        json={OurWork}
      />
      <ContactUs />
      <LocationsComponent />
    </>
  );
};

export default ServicesPage;
