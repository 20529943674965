import React from "react";
import "../styles/trustedSlid.css";
import image1 from "../assets/PdmaLogo.jpeg";
import image2 from "../assets/slideShow2.png";
import image3 from "../assets/VBF- Logo.png";
import img from "../assets/Section.png";

import Slider from "react-slick";

const BannerShowBrand = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="carousel-container">
        <h3>TRUSTED BY +100 BUSINESSES</h3>
        <Slider {...settings}>
          <div className="sliderImgDiv">
            <img src={image1} alt="Coda" />
          </div>
          <div className="sliderImgDiv">
            <img src={image2} alt="Inter" />
          </div>
          <div className="sliderImgDiv">
            <img src={image3} alt="Notion" />
          </div>
          <div className="sliderImgDiv">
            <p
              style={{
                color: "#ffffff",
                fontSize: "40px",
                fontWeight: "bold",

                paddingRight: "30px",
              }}
            >
              Devit.
            </p>
          </div>
          <div className="sliderImgDiv">
            <p
              style={{
                color: "#ffffff",
                fontSize: "40px",
                fontWeight: "bold",
                paddingRight: "30px",
              }}
            >
              LookUp
            </p>
          </div>
        </Slider>
      </div>
      <div className="moon-img">
        <img src={img} alt="" style={{ width: "100%" }} />
      </div>
    </>
  );
};

export default BannerShowBrand;
