import React, { useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";

import img1 from "../assets/VBF- Logo.png";
import img2 from "../assets/slideShow2.png";
import img3 from "../assets/ic_launcher.png";
import "../styles/OurProduct.css";

type Props = {
  title: string;
  OurServices: any;
};

const OurProduct = (props: Props) => {
  const [textslice, setTextSlice] = useState(true);
  const [clickIndex, setClickIndex] = useState(0);
  const textSlice = (text: any, index: any) => {
    if (textslice) {
      return text.slice(0, 150);
    } else {
      if (index === clickIndex) {
        return text;
      } else {
        return text.slice(0, 150);
      }
    }
  };
  return (
    <>
      <section className="our-product">
        <Container>
          <h2
            className="main-heading"
            style={{ color: "#041527", fontSize: "20px" }}
          >
            {props.title}
          </h2>
          <Row className="card-grid">
            {props.OurServices.map((item: any, index: any) => (
              <Col xs={12} md={4} lg={4}>
                <Card
                  style={{ width: "100%" }}
                  // className={index === 1 ? "middle-card" : ""}
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <div
                    style={{
                      width: "100%",
                      height: "200px",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={index === 0 ? img1 : index === 1 ? img2 : img3}
                      className="card-img-top"
                      alt="logo"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title>{item.title}</Card.Title>

                    <Card.Text>{textSlice(item.desc, index)}</Card.Text>
                    <span
                      className="link-readmore"
                      onClick={() => {
                        setClickIndex(index);
                        if (textslice) {
                          setTextSlice(false);
                        } else {
                          setTextSlice(true);
                        }
                      }}
                    >
                      Read More
                    </span>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default OurProduct;
