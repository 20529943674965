import React from "react";
import { Container } from "react-bootstrap";

type Props = {
  title: string;
  desc: string;
};

const ServicesHead = (props: Props) => {
  return (
    <>
      <Container style={{ paddingBottom: "0" }}>
        <div style={{ paddingTop: "80px" }}>
          <h2 className="main-heading" style={{ color: "#041527" }}>
            {props.title}
          </h2>
          <p className="para" style={{ color: "#041527" }}>
            {props.desc}
          </p>
        </div>
      </Container>
    </>
  );
};

export default ServicesHead;
