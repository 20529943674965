import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/OurWork.css";
const OurWork = () => {
  return (
    <>
      <Container
        className="text-center"
        style={{ marginBottom: "0", paddingBottom: "0" }}
        id="aboutUs"
      >
        <h2 className="mainHeading">A BRAND FIRST DIGITAL AGENCY</h2>
        <p className="para themeBlack ">
          What does that mean? It means that all our solutions begin with brand
          research, and all of our work is catered to enhance and collaborate
          with the brand at hand. It also signifies that we are always exploring
          the new and innovating, with a focus on creative storytelling. Since
          our inception in 2016 as a digital agency, we have dedicated ourselves
          to helping brands realize their full potential.
        </p>
      </Container>
    </>
  );
};

export default OurWork;
