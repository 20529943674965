import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage";
import OurTeamPage from "./pages/OurTeamPage";
import ServicesPage from "./pages/ServicesPage";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import ProjectPage from "./pages/ProjectPage";
import ContactUsPage from "./pages/ContactUsPage";
import StackPage from "./pages/StackPage";

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log("first");
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/ourteam" element={<OurTeamPage />} />
        <Route path="/casestudie" element={<ProjectPage />} />
        <Route path="/contactus" element={<ContactUsPage />} />
        <Route path="/technologies" element={<StackPage />} />
      </Routes>
      <Footer />

      {/* <Testimonial /> */}
      {/* <DecBanner
        secondHeading={"Website Design for SCFC Canada"}
        desc={
          "Born out of a vision, a single-minded objective that puts service before anything else, Swift Clearance and Forwarding Corp. surging forth to deliver the best services in the shipping and logistics scenario. Its meteoric rise stems out of a solid foundation. The management boasts of over 20 years of rich and varied experience in the shipping and freight forwarding industry."
        }
      />
      <AboutUs
        title={"Build the right team to scale"}
        secondHeading={
          "Finding the right talent is not easy. We help you find the talent that suits your needs, follows your processes, and sticks with you long term (not the case with freelancers"
        }
        desc={
          "Our delivery model helps you cut costs and deliver within budget."
        }
        display={""}
        img={Img1}
      /> */}
      {/* <TechStack />
      <FeaturedResources /> */}
    </>
  );
};

export default App;
