import React from "react";
import Banner from "../components/Banner";
import BannerShowBrand from "../components/BannerShowBrand";
import OurWork from "../components/OurWork";
import Profile from "../components/Profile";
import LatestProject from "../components/LatestProject";
import CaseStudies from "../components/CaseStudies";
import WorkingApproach from "../components/WorkingApproach";
import OurTeam from "../components/OurTeam";
import IndustryExperties from "../components/IndustryExperties";
import ContactUs from "../components/ContactUs";
import LocationsComponent from "../components/LocationsComponent";

const HomePage = () => {
  return (
    <>
      <Banner />
      <BannerShowBrand />
      <OurWork />
      <Profile />
      <LatestProject />
      <CaseStudies />
      <WorkingApproach />
      <OurTeam />
      <IndustryExperties />
      <ContactUs />
      <LocationsComponent />
    </>
  );
};

export default HomePage;
