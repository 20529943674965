import { Row, Col, Container } from "react-bootstrap";
import "../styles/AboutUs.css";
import img1 from "../assets/digital marketing.jpeg";
import img4 from "../assets/web d.jpeg";
import img3 from "../assets/mobile dev.jpeg";
import img2 from "../assets/Ai.jpeg";

type Props = {
  title: string;
  secondHeading: string;
  desc: string;
  display: string;
  img: any;
  list: string[];
};

const AboutUs = (props: Props) => {
  return (
    <>
      <section className="aboutUs">
        <Container>
          <Row className="aboutUsRow">
            <Col xs={12} md={12} lg={6} className="aboutUscol">
              <h2 className="aboutUs-heading">{props.title}</h2>
              <p className="P-heading" style={{ paddingBottom: 0 }}>
                {props.secondHeading}
              </p>
              <ul>
                {props.list.map((item: any, index: any) => (
                  <li className="li-list">{item}</li>
                ))}
              </ul>
            </Col>
            <Col xs={12} md={12} lg={5}>
              <div className="imgdiv">
                <img
                  src={
                    props.img === "1"
                      ? img1
                      : props.img === "2"
                      ? img4
                      : props.img === "3"
                      ? img3
                      : img2
                  }
                  alt=""
                  width={959}
                  height={649}
                  className="aboutUsimg"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutUs;
