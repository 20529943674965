import React from "react";

import { Container, Row, Col, Card } from "react-bootstrap";
import "../styles/workingapproach.css";
import image from "../assets/Group 34.png";

const WorkingApproach = () => {
  const services = [
    {
      title: "Web Development",
      desc: "Building responsive and functional websites tailored to your needs.",
    },
    {
      title: "Mobile App Development",
      desc: "Creating user-friendly mobile applications for iOS and Android platforms.",
    },
    {
      title: "Backend Infrastructure",
      desc: "Building robust and scalable server-side architecture for applications.",
    },
    {
      title: "Cross-Platform App Development",
      desc: "Developing apps that work seamlessly across multiple platforms using a single codebase.",
    },
    {
      title: "SaaS Platform Development",
      desc: "Creating software-as-a-service platforms for various business needs.",
    },
    {
      title: "Product Building",
      desc: "Designing and developing new products from concept to completion.",
    },

    {
      title: "Facebook Ads",
      desc: "Running targeted ad campaigns to increase visibility and conversions on Facebook.",
    },
    {
      title: "Google Ads",
      desc: "Managing pay-per-click campaigns to drive traffic and boost sales through Google.",
    },
    {
      title: "Social Media Marketing",
      desc: "Promoting brands and businesses through effective social media strategies.",
    },
    {
      title: "Accounting",
      desc: "Managing financial records, taxes, and reporting for businesses.",
    },
    {
      title: "Business Management",
      desc: "Overseeing and coordinating the daily operations and strategic goals of a business.",
    },
  ];

  return (
    <section className="workingapproach" id="services">
      <Container>
        <div className="text-center">
          <h2 className="mainHeading">Services </h2>
          <p className="para themeBlack ">
            Discover our comprehensive suite of services crafted to meet your
            unique requirements. Whether you're looking for web development,
            digital marketing, or animation, our services are geared towards
            delivering top-notch solutions with a focus on your success.Here are
            some of ur latest developoments!
          </p>
        </div>
        <div className="row">
          {services.map((data) => (
            <div className="col-lg-3 col-sm-12">
              <div style={{ borderRadius: "10" }}>
                <div className="wa-card mb-3" style={{ minHeight: "223px" }}>
                  <div>
                    <img src={image} alt="Picture of the author" />
                  </div>
                  <h3 className="wa-card-heading">{data.title}</h3>

                  <div>
                    <p className="wa-card-dec">{data.desc}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default WorkingApproach;
