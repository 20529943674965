import React from "react";
import Stack from "../components/Stack";

const StackPage = () => {
  return (
    <>
      <Stack />
    </>
  );
};

export default StackPage;
