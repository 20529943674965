import React from "react";
import { Container } from "react-bootstrap";
import "../styles/caseStudies.css";
import Img from "../assets/image.png";
import image1 from "../assets/PdmaLogo.jpeg";
import image2 from "../assets/slideShow2.png";
import image3 from "../assets/VBF- Logo.png";

import Slider from "react-slick";

const CaseStudies = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const data = [
    {
      color: "#041527",
      title: "Devit.",
      dec: "Devit is an AI-driven IT firm that we supported by strengthening their marketing strategy and supplying a skilled developer. Our collaboration helped enhance their market presence and expand their technical capabilities, enabling them to deliver cutting-edge solutions in the AI and IT sectors.",
    },
    {
      color: "#041527",
      title: "LookUp",
      dec: "Lookup is an AI-driven shopping app designed to transform the way users shop online. With advanced machine learning algorithms, Lookup personalizes the shopping experience, offering tailored product recommendations, real-time price comparisons, and streamlined navigation. By analyzing user preferences and shopping patterns, the app connects users to the products they need, quickly and effortlessly. Lookup’s success lies in its ability to reduce search time and enhance satisfaction, making online shopping more efficient and enjoyable for consumers.",
    },
    {
      color: "#041527",
      title: "PDTMA",
      dec: "PDTMA is crafted with an inviting, innovative design to help users navigate effortlessly. By prioritizing ease of navigation, PDTMA simplifies complex workflows, ensuring users can achieve their tasks smoothly and intuitively. With a focus on user-centered design, PDTMA stands out as a solution that elevates functionality and user experience.",
    },
    {
      color: "#041527",
      title: "Butt Karahi",
      dec: "Butt Karahi is a specialized hotel management app designed to streamline the reservation process with online booking and seat reservation features. It simplifies operations by allowing users to book tables or hotel rooms directly from their devices, enhancing customer convenience and ensuring seamless management for the hotel staff. With an intuitive interface, the app optimizes occupancy rates and helps manage reservations effortlessly, creating a smooth experience for both customers and staff.",
    },
    {
      color: "#041527",
      title: "Vision",
      dec: "Vision is an online portal developed for NGOs to simplify and manage the application process. Designed to accept and organize online applications, Vision makes it easier for NGOs to connect with volunteers, beneficiaries, and supporters. With user-friendly forms and efficient data management, Vision enables NGOs to streamline outreach efforts and respond quickly, enhancing their impact in communities.",
    },
  ];
  return (
    <>
      <Container id="caseStudie">
        <div className="text-center">
          <h2 className="mainHeading">Case Studies</h2>
          <p className="para themeBlack ">
            Explore real-world examples of our successful projects. Our case
            studies showcase the impact of our work, highlighting how we've
            helped businesses like yours overcome challenges and achieve their
            goals.
          </p>
        </div>

        <Slider {...settings}>
          {data.map((data: any, i: number) => (
            <div
              className="sliderDIv "
              style={{
                marginBottom: "20px",
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <div className="col-md-12 " style={{ paddingTop: "18px" }}>
                <h1 className="themeBlack" style={{ color: data.color }}>
                  {data.title}
                </h1>
                <p
                  className="para themeBlack"
                  style={{ fontSize: "18px", marginBottom: "20px" }}
                >
                  {data.dec}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </Container>
    </>
  );
};

export default CaseStudies;
